@import "../node_modules/angular-calendar/css/angular-calendar.css";

.cal-month-view .cal-cell-top {
  background-color: lightblue !important;
}

.cal-month-view .cal-cell-row {
  border-bottom: 1px solid;
  border-color: #061767;
  background-color: lightsteelblue;
}

.cal-month-view .cal-days .cal-cell-row {
  border-color: #86b8ea;

}

.simple-notification-wrapper {
  z-index: 999999 !important;
}

.pac-container {
  margin-top: 40px !important;
  padding-bottom: 50px !important;
  margin-right: 12px !important;
  z-index: 6000 !important;
  position: absolute !important;
  top: 5vh !important;
}

.gauge-chart__label {
  font-weight: 700;
  font-size: 15px !important;
  margin-top: -45px !important;
}

.custom-clustericon {
  background: var(--cluster-color);
  color: #fff;
  border-radius: 100%;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.custom-clustericon::before,
.custom-clustericon::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: var(--cluster-color);
  opacity: 0.2;
  border-radius: 100%;
}

.custom-clustericon::before {
  padding: 7px;
}

.custom-clustericon::after {
  padding: 14px;
}

.custom-clustericon-1 {
  --cluster-color: #00a2d3;
}

.custom-clustericon-2 {
  --cluster-color: #ff9b00;
}

.custom-clustericon-3 {
  --cluster-color: #ff6969;
}

.datatable-scroll {
  padding-bottom: 0px !important;
}

.highcharts-credits {
  display: none !important
}


.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.8);
  cursor: progress;
}

.card-disabled .card-portlets-loader {
  background-color: #145388;
  -webkit-animation: rotatebox 1.2s infinite ease-in-out;
  animation: rotatebox 1.2s infinite ease-in-out;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 3px;
  margin-left: -12px;
  margin-top: -12px;
}

@-webkit-keyframes rotatebox {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes rotatebox {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }

  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }

  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.ng-select.ng-select-single .ng-select-container {
  width: 217px ;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.tippy-box{
  background-color: #145388 !important; 
  color: white; 
}



